.services-container {
    justify-content: center;
    padding: 40px;
    background-image: radial-gradient(circle farthest-corner at 0% 0%, var(--primary-blue) -10%, rgb(220, 220, 220) 30.3%, rgb(159, 175, 255) 300%);
  }

  .service-title-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .service-title {
    flex: 1;
    font-size: 1.2rem;
  }
  
  .service-actions button {
    border: none;
    background: transparent;
    cursor: pointer;
    transition: 0.3s;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .service-actions button:hover {
    transform: scale(1.1);
  }
  
  .edit-btn {
    background: #007bff;
    color: white;
  }
  
  .delete-btn {
    background: #dc3545;
    color: white;
  }
  
  .add-service-form {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .add-service-form input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-btn {
    background: #28a745;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .add-btn:hover {
    background: #218838;
  }
  
  /* Edit Mode */
  .edit-mode {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .edit-mode input {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .save-btn {
    background: #28a745;
    color: white;
    padding: 5px 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cancel-btn {
    background: #dc3545;
    color: white;
    padding: 5px 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  @media (max-width: 1200px) {
    .services-container {
      padding: 20px;
    }
  }
  